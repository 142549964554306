import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';

import Mail from '../../images/envelope-icon.svg';

import './Dialog.scss';

const Dialog = ({ show, setShow, email, handleClick, storeConfig }) => (
  <Modal
    show={show}
    onHide={() => setShow(false)}
    centered
    dialogClassName="Dialog"
    backdropClassName={classNames('Dialog__backdrop', { isTrashie: storeConfig.isTrashie })}
  >
    <Modal.Body>
      <div className="modalContent">
        <div className="imgContainer">
          <img src={Mail} alt="mail" />
        </div>
        <div className="descriptionContainer">
          {storeConfig?.registerTbbFlow?.confirmEmailStep.descriptionText}
        </div>
        <div className="emailContainer">
          {email}
        </div>
        <div className="actions">
          <Button
            onClick={() => { handleClick(); setShow(false); }}
            style={{
              background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
              color: storeConfig.registerTbbFlow?.nextButton?.textColor,
              borderColor: !storeConfig?.isTrashie &&
              storeConfig.registerTbbFlow?.nextButton?.bgColor,
            }}
          >
            {storeConfig?.registerTbbFlow?.confirmEmailStep.yesOption}
          </Button>
          <span onClick={() => setShow(false)} aria-hidden="true">{storeConfig?.registerTbbFlow?.confirmEmailStep.noOption}</span>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default Dialog;
