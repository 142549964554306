import { Button } from 'react-bootstrap';
import InfoSection from '../InfoSection/InfoSection';
import style from './ConfirmAddress.module.scss';
import SvgComponentArrow from '../SvgComponentArrow';

const {
  container,
  addressCardEditButton,
  addressCardInfo,
  button,
  currentAddress,
  buttonContainer,
  addressTrashieCard,
  addressPartnerCard,
  infoContainer,
  addressContainer,
} = style;

const ConfirmAddress = ({ address, onEditAddress, onContinue, storeConfig }) => (
  <div className={container}>
    <div className={infoContainer}>
      <InfoSection
        titleText="Confirm your mailing information"
        descriptionText="Here’s the address we have on file for you, is this right?"
      />
    </div>
    <div className={addressContainer}>
      <p className={currentAddress}>Your Current Address</p>
      <div className={storeConfig.isTrashie ? addressTrashieCard : addressPartnerCard}>
        <div className={addressCardInfo}>
          {address.firstName}
          {' '}
          {address.lastName}
          <br />
          {address.address1 && `${address.address1}${address.address2 && ','}`}
          {address.address2 && ` ${address.address2}`}
          {' '}
          <br />
          {address.city && `${address.city}, `}
          {address.provinceCode}
          {' '}
          {address.zip}
        </div>
        <Button onClick={onEditAddress} className={addressCardEditButton}>Edit</Button>
      </div>
    </div>

    <div className={buttonContainer}>
      <Button
        className={button}
        style={{
          background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
          color: storeConfig.registerTbbFlow?.nextButton?.textColor,
          border: !storeConfig.isTrashie && storeConfig.registerTbbFlow?.nextButton?.bgColor,
        }}
        onClick={onContinue}
        disabled={Object.keys(address) === 0}
      >
        CONTINUE
        <SvgComponentArrow fill={storeConfig.registerTbbFlow?.nextButton?.textColor} />
      </Button>
    </div>
  </div>
);

export default ConfirmAddress;
