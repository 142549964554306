import { useState, useCallback } from 'react';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../Button';
import style from './TbbItemRow.module.scss';
import TbbGrey from '../../../images/take-back-bags-gray.svg';
import OldTbb from '../../../images/old-tbb.png';
import { formatMMDDYY } from '../../../utils/dates';
import QrModal from '../../QrModal';
import { shippingProviderStatus, qrIsExpired, isExpiredLabel } from '../../../utils/easyPost';
import { postShippingLabelRegenerate } from '../../../utils/service';
import LoadingBar from '../../LoadingBar';

const {
  container,
  infoContainer,
  button,
  titleStyle,
  subtitleStyle,
  imageStyle,
  dataContainer,
  deliveredDateContainer,
  shippedDateContainer,
  shippedExpiredDateContainer,
  actionContainer,
  imageContainer,
} = style;

const ItemRow = ({ itemData, setTbbData }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    forms,
    postage_label: postageLabel,
  } = itemData?.labeldata || {};
  const { code: redemptionCode, pin, redeemedAt } = itemData;
  const { shipmentStatus, shippingLabelExpiresAt, shippedAt, deliveredAt } =
  itemData.shipment ?? {};

  const isShipped = shipmentStatus === shippingProviderStatus.in_transit ||
  shipmentStatus === shippingProviderStatus.out_for_delivery;

  const statusDateTag = () => {
    if (shipmentStatus === shippingProviderStatus.delivered) {
      return (
        <div className={deliveredDateContainer}>
          Delivered:
          {' '}
          {formatMMDDYY(deliveredAt)}
        </div>
      );
    }
    if (isShipped) {
      return (
        <div className={shippedDateContainer}>
          Shipped:
          {' '}
          {formatMMDDYY(shippedAt)}
        </div>
      );
    }
    if (isExpiredLabel(shippingLabelExpiresAt)) {
      return (
        <div className={shippedExpiredDateContainer}>
          Label expired:
          {' '}
          {formatMMDDYY(shippingLabelExpiresAt)}
        </div>
      );
    }

    return (
      <div className={shippedDateContainer}>
        Ship by:
        {' '}
        {formatMMDDYY(shippingLabelExpiresAt)}
      </div>
    );
  };

  const regenerateLabel = useCallback(async () => {
    setIsLoading(true);
    const { data } = await postShippingLabelRegenerate(redemptionCode);
    setTbbData((prev) => prev.map((item) => (item.id === itemData.id ?
      { ...data, labeldata: JSON.parse(data.labeldata) } : item)));
    setIsLoading(false);
    setShowModal(true);
  }, []);

  const buttonOptions = () => {
    if (isExpiredLabel(shippingLabelExpiresAt)) {
      return {
        label: 'CREATE NEW LABEL',
        function: regenerateLabel,
      };
    }
    if (qrIsExpired(redeemedAt, shipmentStatus, shippingLabelExpiresAt)) {
      return {
        label: 'PRINT SHIPPING LABEL',
        function: () => { window.open(postageLabel?.label_pdf_url, '_blank'); },
      };
    }

    return {
      label: 'VIEW SHIPPING LABEL',
      function: setShowModal,
    };
  };

  return (
    <div className={container}>
      <QrModal
        show={showModal}
        setShow={setShowModal}
        qrUrl={forms?.[0]?.form_url}
        pdfUrl={postageLabel?.label_pdf_url}
        expireDate={formatMMDDYY(shippingLabelExpiresAt)}
      />
      <div className={dataContainer}>
        <div className={imageContainer}>
          <img src={pin ? TbbGrey : OldTbb} alt="tbb" className={imageStyle} />
        </div>
        <div className={actionContainer}>
          <div className={infoContainer}>
            <div className={titleStyle}>{`ID: ${itemData.id}`}</div>
            {pin && shipmentStatus !== shippingProviderStatus.delivered &&
            !isShipped && <div className={shippedDateContainer}>{`PIN: ${pin}`}</div>}
            {redeemedAt &&
              shipmentStatus !== shippingProviderStatus.delivered &&
              !isShipped &&
              (
                <div className={subtitleStyle}>{`Registered on: ${formatMMDDYY(redeemedAt)}`}</div>
              )}
            {statusDateTag()}
          </div>
          {
            isLoading ? <LoadingBar /> :
              shipmentStatus !== shippingProviderStatus.delivered &&
            !isShipped &&
            (
              <Button
                type={BUTTON_TYPE.SECONDARY}
                size={BUTTON_SIZE.LARGE}
                className={button}
                onClick={() => buttonOptions().function(true)}
              >
                {buttonOptions().label}
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ItemRow;
