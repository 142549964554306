import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { emailFormat } from '../../utils/emailFormatValidator';
import Input from '../Input';
import InfoSection from '../InfoSection/InfoSection';
import SvgComponentArrow from '../SvgComponentArrow';
import style from './RegisterEmail.module.scss';
import Dialog from '../Dialog';

const { container, infoContainer, emailContainer, buttonContainer, button, inputEmail } = style;
const RegisterEmail = ({ storeConfig, email, onEmailChange, handleEmailStep, creditAmt }) => {
  const [formatError, setFormatError] = useState(false);
  const [show, setShow] = useState(false);

  const handleOnclick = () => {
    if (emailFormat(email)) {
      setShow(true);
    } else {
      setFormatError(true);
    }
  };

  const handleEmailChange = (e) => {
    setFormatError(false);
    onEmailChange(e);
  };

  return (
    <div className={container}>
      <Dialog
        show={show}
        setShow={setShow}
        email={email}
        handleClick={handleEmailStep}
        storeConfig={storeConfig}
      />
      <div>
        <div className={infoContainer}>
          <InfoSection
            titleText="What’s your email?"
            descriptionText={storeConfig?.registerTbbFlow.enterEmailStep.descriptionText.replace(
              '[creditAmount]',
              storeConfig.isTrashie ?
                creditAmt :
                storeConfig?.registerTbbFlow?.rewardStep?.cashValue,
            )}
          />
        </div>
        <div className={emailContainer}>
          <Input
            type="text"
            id="email"
            label="Email*"
            placeholder="name@email.com"
            value={email}
            errorMessage={formatError ? 'Oops, seems like this email needs a fix.' : null}
            onChange={handleEmailChange}
            className={!storeConfig.isTrashie && inputEmail}
          />
        </div>
      </div>

      <div className={buttonContainer}>
        <Button
          className={button}
          style={{
            background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            color: storeConfig.registerTbbFlow?.nextButton?.textColor,
            border: !storeConfig.isTrashie && storeConfig.registerTbbFlow?.nextButton?.bgColor,
          }}
          onClick={() => handleOnclick()}
          disabled={!email}
        >
          CONTINUE
          <SvgComponentArrow fill={!email ? '#6F706F' : storeConfig.registerTbbFlow?.nextButton?.textColor} />
        </Button>
      </div>
    </div>
  );
};

export default RegisterEmail;
