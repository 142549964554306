/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
import * as React from 'react';
import { useState } from 'react';
import { Card, Button, FormGroup, FormControl } from 'react-bootstrap';
import PinComponent from './Components/PinComponent';
import Style from './RedemptionLandingPage.module.scss';

const {
  banner,
  firstLegend,
  buttonTrashie,
  buttonContainer,
  buttonEdit,
  inputEmail,
  addressVerificationLegend,
  emailLegend,
  pinLegend,
  pinLegendSecond,
  pinlegendThird,
  buttonContainerEdit,
  emailLengendContainer,
  inputEmailError,
  emailErrorText,
} = Style;

export default function RedemptionTbbForm({
  redemptionCode,
  attempts,
  isValidPin,
  storeConfig,
  codeFound,
  codeFoundMessage,
  requirePin,
  emailInput,
  pinValues,
  setPinValues,
  isTbbFormBtnDisabled,
  onSubmit,
  onEmailInputChange,
  address,
  onEditAddress,
  isValidEmail,
}) {
  const [validInputEEmailn, setValidInputEEmail] = useState(null);
  const formattedEmail = emailInput || null;
  const formattedPin = pinValues.join('') || 'N/A';
  const subject = encodeURI(`Pin Trouble - ${redemptionCode}`);
  const body = encodeURI(`The platform is not allowing me to redeem my bag with code ${redemptionCode} and ${formattedPin}, could you please email me back at ${emailInput} with instructions on how to proceed?`);
  const csHref = formattedEmail ? `mailto:help@fordays.com?subject=${subject}&body=${body}` : '#';
  const onEEmailInputChange = (e) => {
    setValidInputEEmail(e.target.value.toLowerCase() !== '');
    onEmailInputChange(e);
  };

  return (
    <>
      <Card>
        <div
          className={banner}
          style={{ backgroundColor: storeConfig.color1, color: storeConfig.color2 }}
        >
          THE <br /> TAKE BACK  <br /> BAG
        </div>

        {codeFoundMessage != null && (
          <>
            <h4
              className="m-0"
              style={{
                paddingTop: '32px',
                color: '#363436',
                fontFamily: 'GT-America',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '120%',
              }}
            >
              {codeFoundMessage}
            </h4>
            <FormGroup className="pb-md-32 pt-64">
              <span style={{
                fontFamily: 'GT-America',
                fontSize: '18px',
                fontWeight: '300',
                lineHeight: '25px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#A6A6A6',
              }}
              >
                Enter your email address
                {(validInputEEmailn != null && !validInputEEmailn) && (
                  <>
                    <br />
                    <small className="text-red">required field</small>
                  </>
                )}
              </span>
              <FormControl
                style={{
                  border: '0.5px solid ' + (validInputEEmailn != null && !validInputEEmailn ? '#EE7056' : '#A6A6A6'),
                  backgroundColor: validInputEEmailn != null && !validInputEEmailn ? 'rgba(238, 112, 86, 0.20)' : '#FFF',
                  borderRadius: '10px',
                  textTransform: 'uppercase',
                }}
                className={isValidEmail ? inputEmail : inputEmailError}
                value={emailInput}
                type="email"
                onChange={onEEmailInputChange}
              />
            </FormGroup>
            <span
              style={{
                color: '#EE7056',
                fontFamily: 'GT-America',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
            >
              Pin Trouble?&nbsp;
              <a
                href={csHref}
                style={{
                  color: '#EE7056',
                  fontFamily: 'GT-America',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  textDecoration: 'underline',
                }}
                onClick={(e) => {
                  if (formattedEmail === null) {
                    e.preventDefault();
                    setValidInputEEmail(false);
                  } else {
                    setValidInputEEmail(true);
                  }
                }}
              >
                Contact Customer Support
              </a>
            </span>
          </>
        )}

        {(!codeFoundMessage && codeFound && attempts > 0) && (
          <>
            <h4 className={firstLegend}>
              { address.firstName ? 'Confirm your address' : 'Let\'s Get Started' }
            </h4>
            { !address.firstName && (
              <FormGroup className="pb-md-32 py-24">
                <div className={emailLengendContainer}>
                  {storeConfig?.subheader && (
                  <div className={emailLegend}>
                    {storeConfig?.subheader}
                  </div>
                  )}
                  <div className={emailLegend}>
                    Enter your email address
                  </div>
                </div>
                <FormControl
                  style={{
                    border: '0.5px solid ' + (isValidPin != null && !isValidPin ? '#EE7056' : 'var(--key-line-grey, #A6A6A6)'),
                    backgroundColor: isValidPin != null && !isValidPin ? 'rgba(238, 112, 86, 0.20)' : '#FFF',
                    borderRadius: '10px',
                  }}
                  className={isValidEmail ? inputEmail : inputEmailError}
                  value={emailInput}
                  type="text"
                  onChange={onEmailInputChange}
                />
                {!isValidEmail && (
                <p className={emailErrorText}>
                  Please enter a valid email address
                </p>
                )}
              </FormGroup>
            )}

            {requirePin && (
              <PinComponent
                isValidPin={isValidPin}
                pinValues={pinValues}
                setPinValues={setPinValues}
                styleProps={{ pinLegend, pinLegendSecond, pinlegendThird }}
              />
            )}

            {(attempts < 5 && !isValidPin) && (
              <div>
                <p
                  style={{
                    marginTop: '8px',
                    color: '#EE7056',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                    fontFamily: 'GT-America',
                  }}
                >
                  That pin was incorrect. You have {attempts} more attempts left.
                </p>
              </div>
            )}
            {address.firstName && (
              <div style={{ marginBottom: '24px' }}>
                <p className={addressVerificationLegend}>
                  Is this address correct?
                </p>
                <p className="address-container">
                  <span>
                    {address.firstName} {address.lastName} <br />
                    {address.address1 && `${address.address1}${address.address2 && ','}`}
                    {address.address2 && ` ${address.address2}`} <br />
                    {address.city && `${address.city}, `}
                    {address.provinceCode} {address.zip}
                  </span>
                  <span className={buttonContainerEdit}>
                    <Button
                      onClick={onEditAddress}
                      className={buttonEdit}
                    >
                      Edit
                    </Button>
                  </span>
                </p>
              </div>
            )}
            <div className={buttonContainer}>
              <Button
                onClick={onSubmit}
                type="button"
                disabled={isTbbFormBtnDisabled}
                className={buttonTrashie}
                style={{ backgroundColor: storeConfig.color1, color: storeConfig.color2 }}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </Card>
      {/* {!SstoreConfig.isTrashie && (
      <Card className="col-12 border border-dark bg-white mt-16 px-lg-80 text-center">
        <div className="row py-16 align-items-center">
          <div className="col-6">
            <small>Dont have an account?</small>
          </div>
          <div className="col-6">
            <a className="text-danger m-0" href="/signup">Create Account</a>
          </div>
        </div>
      </Card>
    )} */}
    </>
  );
}
