import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import 'firebase/auth';
import firebase from 'firebase/app';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import DisclaimerPublic from '../DisclaimerPublic';
import DotsLoader from '../DotsLoader';
import GoogleButton from '../GoogleButton';
import Input from '../InputNew';

import TRANSACTIONAL_MESSAGE_IDS from '../../config/customerio';
import { ROUTE_SIGNUP } from '../../config/routes';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../config/externalURL';
import { EVENT_STREAM_APP_TYPES } from '../../config/service';
import TrashieCoinLogo from '../../images/trashie-coin-logo.svg';
import {
  getGenericError,
  isAuthCredentialsError,
  isTooManyRequestsError,
} from '../../utils/errors';
import crud from '../../../../api/crud';
import { useAlert } from '../../../providers/AlertProvider';
import { signIn, signInWithGoogle } from '../../../../utils/auth';
import { fbKey } from '../../../../utils/firebase';

import './GlobalLogin.scss';
import NavigationBar from '../NavigationBar/NavigationBar';

export const viewType = {
  VIEW_EMAIL_FORM: 'emailForm',
  VIEW_EMAIL_SENT: 'emailSent',
  VIEW_EMAIL_PASSWORD_FORM: 'emailAndPassword',
};

const GlobalLogin = ({
  redirectTo,
  // external,
  initialView = viewType.VIEW_EMAIL_PASSWORD_FORM,
  initialEmail = '',
  onViewChange,
  isParentLoading,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const setAlert = useAlert();

  const [email, setEmail] = useState(initialEmail);
  const [code, setCode] = useState('');
  const [view, setView] = useState(initialView);
  const [loginEmail, setloginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loading = useMemo(() => (isParentLoading || isLoading), [isParentLoading, isLoading]);

  const handleRedirect = useCallback(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  const handleSendEmail = useCallback(async (userEmail) => {
    try {
      setIsLoading(true);

      await crud.post({
        path: '/email', // TODO
        body: {
          email: userEmail,
          messageId: TRANSACTIONAL_MESSAGE_IDS.VERIFICATION_CODE,
          messageData: {},
          application: EVENT_STREAM_APP_TYPES.TRASHIE,
        },
      });

      setView(viewType.VIEW_EMAIL_SENT);
      setCode('');
      setIsLoading(false);
      setEmail(userEmail);
    } catch (error) {
      setErrorMessage('Please enter a valid email address');
      setIsLoading(false);
    }
  }, []);

  const handleVerifyCodeEmail = useCallback(async (userCode) => {
    try {
      setIsLoading(true);

      const token = await crud.post({
        path: '/auth', // TODO
        body: {
          email,
          code: userCode,
        },
      });

      await firebase.auth().signInWithCustomToken(token);

      handleRedirect();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Invalid code. Verify it and try again or get a new code.');
    }
  }, [handleRedirect, email]);

  const handleUserLogin = useCallback(async () => {
    if (!loginEmail || !password) {
      return;
    }

    try {
      setErrorMessage('');
      await signIn(loginEmail, password);

      handleRedirect();
    } catch (error) {
      if (isAuthCredentialsError(error)) {
        setErrorMessage('Invalid email or password. Please check your credentials and try again.');
        return;
      }

      if (isTooManyRequestsError(error)) {
        setErrorMessage('Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.');
        return;
      }

      setErrorMessage(getGenericError());
    }
  }, [loginEmail, password]);

  const handleSignInWithGoogle = useCallback(async () => {
    try {
      await signInWithGoogle();

      handleRedirect();
    } catch (error) {
      setAlert({
        type: 'notification', // TODO
        message: 'Unable to sign in with google',
        error,
      });
    }
  }, [handleRedirect]);

  const checkLocalUserData = useCallback(() => {
    if (redirectTo) {
      const dbRequest = window.indexedDB.open('firebaseLocalStorageDb', 1); // TODO

      dbRequest.onsuccess = async () => {
        const db = dbRequest.result;
        const stores = ['firebaseLocalStorage']; // TODO

        const tx = db.transaction(stores);
        const req = tx.objectStore('firebaseLocalStorage').getAll(); // TODO
        req.onsuccess = () => {
          const localUserData = req.result.find(({ fbase_key: fbBaseKey }) => fbBaseKey === fbKey);
          if (localUserData) {
            handleRedirect();
          }
        };
      };
    }
  }, [redirectTo, handleRedirect]);

  const handleOnEmailChange = useCallback((newValue) => {
    setErrorMessage('');
    setEmail(newValue);
  }, []);

  const handleOnLoginEmailChange = useCallback((newValue) => {
    setErrorMessage('');
    setloginEmail(newValue);
  }, []);

  const handleOnPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setPassword(newValue);
  }, []);

  const handleOnCodeChange = useCallback((newValue) => {
    setErrorMessage('');
    setCode(newValue);
  }, []);

  const handleGetNewCode = useCallback(() => {
    setErrorMessage('');
    setEmail('');
    setView(viewType.VIEW_EMAIL_FORM);
  }, []);

  const handleForgotPassword = useCallback(() => {
    setErrorMessage('');
    setEmail('');
    setView(viewType.VIEW_EMAIL_FORM);
  }, []);

  const handleAlreadyHaveAccount = useCallback(() => {
    setErrorMessage('');
    setEmail('');
    setView(viewType.VIEW_EMAIL_PASSWORD_FORM);
  }, []);

  const goToSignup = useCallback(() => {
    history.replace({
      pathname: ROUTE_SIGNUP,
      search,
    });
  }, [search]);

  const isActiveView = useCallback((viewId) => (
    viewId === view
  ), [view]);

  useEffect(() => {
    if (onViewChange) {
      onViewChange(view);
    }
  }, [onViewChange, view]);

  useEffect(() => {
    window.addEventListener('storage', checkLocalUserData);

    return () => {
      window.removeEventListener('storage', checkLocalUserData);
    };
  }, []);

  return (
    <div className="GlobalLogin">
      <div className="GlobalLogin__content">
        {isActiveView(viewType.VIEW_EMAIL_FORM) && (
          <>
            <NavigationBar
              title="Forgot Password"
              onGoBack={handleAlreadyHaveAccount}
              className="GlobalLogin__navigation"
            />
            <div className="GlobalLogin__content--header">
              <p className="subtitle">Enter your email address</p>
              <p className="text">
                We will send you a one-time login code to verify your account
              </p>
            </div>
            <div className="GlobalLogin__content--form">
              <Input
                type="text"
                id="email"
                placeholder="Email"
                value={email}
                onChange={handleOnEmailChange}
                errorMessage={errorMessage}
                disabled={loading}
              />
              <Button
                type={BUTTON_TYPE.QUATERNARY}
                size={BUTTON_SIZE.LARGE}
                onClick={() => handleSendEmail(email.toLowerCase())}
                disabled={!email || loading}
              >
                {loading ? <DotsLoader /> : 'Get one-time login code'}
              </Button>
            </div>
          </>
        )}
        {isActiveView(viewType.VIEW_EMAIL_SENT) && (
          <>
            <NavigationBar
              title="Get one-time login code"
              onGoBack={handleGetNewCode}
              className="GlobalLogin__navigation"
            />
            <div className="GlobalLogin__content--form">
              <Input
                type="text"
                id="code"
                label={`Enter the one-time code we've sent to ${email}`}
                placeholder="Code"
                value={code}
                onChange={handleOnCodeChange}
                errorMessage={errorMessage}
                disabled={loading}
              />
              <Button
                type={BUTTON_TYPE.QUATERNARY}
                size={BUTTON_SIZE.LARGE}
                onClick={() => handleVerifyCodeEmail(code)}
                disabled={!code || loading}
              >
                {loading ? <DotsLoader /> : 'Verify'}
              </Button>
              <Button
                type={BUTTON_TYPE.LINK}
                onClick={handleGetNewCode}
              >
                Get a new code
              </Button>
            </div>
          </>
        )}
        {isActiveView(viewType.VIEW_EMAIL_PASSWORD_FORM) && (
          <>
            <img className="GlobalLogin__logo" src={TrashieCoinLogo} alt="logo" />
            <GoogleButton onClick={() => handleSignInWithGoogle()} />
            <p className="GlobalLogin__content--label">
              OR
            </p>
            <div className="GlobalLogin__content--form">
              <Input
                type="text"
                id="userEmail"
                placeholder="Email"
                value={loginEmail}
                onChange={handleOnLoginEmailChange}
                errorMessage={Boolean(errorMessage)}
                disabled={loading}
              />
              <Input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={handleOnPasswordChange}
                errorMessage={errorMessage}
                disabled={loading}
              />
              <Button
                type={BUTTON_TYPE.QUATERNARY}
                size={BUTTON_SIZE.LARGE}
                onClick={handleUserLogin}
                disabled={loading}
              >
                {loading ? <DotsLoader /> : 'Log in'}
              </Button>
              <Button
                type={BUTTON_TYPE.LINK_QUINARY}
                onClick={handleForgotPassword}
              >
                Forgot password?
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="GlobalLogin__options">
        <div className="GlobalLogin__options--option">
          <p className="GlobalLogin__options--option-text">
            Need a new account?
          </p>
          <Button
            className="GlobalLogin__options--option-link"
            type={BUTTON_TYPE.LINK_QUINARY}
            onClick={goToSignup}
          >
            Register
          </Button>
        </div>
        {isActiveView(viewType.VIEW_EMAIL_PASSWORD_FORM) && (
          <DisclaimerPublic
            termsUrl={TERMS_OF_SERVICE_URL}
            policyUrl={PRIVACY_POLICY_URL}
          />
        )}
      </div>
    </div>
  );
};

export default GlobalLogin;
