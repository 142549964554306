import { TRASHIE_GA_TAG_ID } from './config';

import { isProd } from '../../../config/config';

const TRASHIE_WEBSITE_URL = 'https://trashie.io';
const FORDAYS_WEBSITE_URL = 'https://fordays.com';
const HELP_URL = 'https://help.trashie.io/';
const TERMS_OF_SERVICE_URL = `${TRASHIE_WEBSITE_URL}/terms-of-service`;
const PRIVACY_POLICY_URL = `${TRASHIE_WEBSITE_URL}/privacy-policy`;
const SUPPORT_URL = `${TRASHIE_WEBSITE_URL}/support`;
const BUG_REPORT_URL = `${TRASHIE_WEBSITE_URL}/bug-report`;
const THE_TBB_URL = `${TRASHIE_WEBSITE_URL}/the-tbb`;
const HOW_WE_RECYCLE_URL = `${TRASHIE_WEBSITE_URL}/how-we-recycle`;
const CONTACT_US_URL = `${TRASHIE_WEBSITE_URL}/contact`;
const PARTNERSHIPS_URL = `${TRASHIE_WEBSITE_URL}/partnerships`;
const BROWSER_EXTENSION_URL = `${TRASHIE_WEBSITE_URL}/browser-extension`;
const FAQS_URL = `${TRASHIE_WEBSITE_URL}/faqs`;
const TAKE_BACK_BAG_COLLECTION_URL = `${TRASHIE_WEBSITE_URL}/tbb-collection`;

const SHOP_TRASHIE_URL = 'https://shop.trashie.io';
const TAKE_BACK_BAG_URL = `${SHOP_TRASHIE_URL}/products/take-back-bag`;
const TAKE_BACK_BAG_RAINBOW_3_PACK_URL = `${SHOP_TRASHIE_URL}/products/take-back-bag-rainbow-3-pack`;
const TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL = `${SHOP_TRASHIE_URL}/products/cotton-fabric-scrap-take-back-bag`;
const TAKE_BACK_BAG_DOUBLE_RAINBOW_URL = `${SHOP_TRASHIE_URL}/products/double-rainbow-deal-by-trashie`;
const DOWNLOAD_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/trashie/mhaamcbaeggmpmmdppgeimpkmnfmhgpc';

const PARTNERSHIPS_MAIL_TO = "mailto:partnerships@trashie.io?subject=I'd%20love%20to%20get%20more%20information%20about%20partnering%20with%20Trashie!";

const TRASHIE_SOCIAL_NETWORKS = {
  INSTAGRAM: 'https://www.instagram.com/trashie',
  TIKTOK: 'https://www.tiktok.com/@trashie',
  FACEBOOK: 'https://www.facebook.com/fordaysfordays',
};

const GOOGLE_TAG_MANAGER_URL =
  `https://www.googletagmanager.com/gtag/js?id=${isProd() ? TRASHIE_GA_TAG_ID.PRODUCTION : TRASHIE_GA_TAG_ID.STAGING}`;

const WILDLINK_AFFILIATE_LINK_URL = 'https://wild.link/e';

export {
  TRASHIE_WEBSITE_URL,
  FORDAYS_WEBSITE_URL,
  HELP_URL,
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  SUPPORT_URL,
  BUG_REPORT_URL,
  TAKE_BACK_BAG_URL,
  TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
  TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL,
  TAKE_BACK_BAG_DOUBLE_RAINBOW_URL,
  DOWNLOAD_EXTENSION_URL,
  PARTNERSHIPS_URL,
  BROWSER_EXTENSION_URL,
  FAQS_URL,
  TAKE_BACK_BAG_COLLECTION_URL,
  PARTNERSHIPS_MAIL_TO,
  THE_TBB_URL,
  HOW_WE_RECYCLE_URL,
  CONTACT_US_URL,
  TRASHIE_SOCIAL_NETWORKS,
  GOOGLE_TAG_MANAGER_URL,
  WILDLINK_AFFILIATE_LINK_URL,
};
