/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { usePinInput } from 'react-pin-input-hook';

function PinComponent({
  isValidPin,
  pinValues,
  setPinValues,
  styleProps,
}) {
  const { fields } = usePinInput({
    pinValues,
    onChange: (nPinValues) => {
      setPinValues(nPinValues);
    },
  });

  let pinClassNames = 'form-control pin-input__field cs-pin-inputs';
  if (!isValidPin) {
    pinClassNames += ' cs-pin-inputs-wer';
  }

  return (
    <>
      <div>
        <div className={styleProps.pinLegend}>
          Enter your Pin.
        </div>
      </div>
      <div className="pin-fields">
        {fields.map((propsField, index) => (
          <input
            style={{
              border: !isValidPin ? '2px solid #E53200' : '1px solid #1B1A1B',
              backgroundColor: styleProps.background,
              color: isValidPin ? '#000' : '#E53200',
              width: '53px',
              height: '53px',
            }}
            key={index}
            className={`${styleProps.pinBox} ${pinClassNames}`}
            {...propsField}
            placeholder="0"
            value={pinValues[index]}
          />
        ))}
      </div>
    </>
  );
}

export default PinComponent;
