import style from './InfoSection.module.scss';

const { container, title, description } = style;
const InfoSection = ({ titleText = '', descriptionText = '' }) => (
  <div className={container}>
    <div className={title}>{titleText}</div>
    <div className={description}>{descriptionText}</div>
  </div>
);

export default InfoSection;
