import style from './SupportContact.module.scss';

const { supportContainer, supportContainerButton } = style;
const SUPPORT_EMAIL = 'help@trashie.io';
const SupportContact = ({ text = 'contact support', registrationCode, pin, email, isButton = false }) => {
  const subject = encodeURI(`Registration TBB Trouble - ${registrationCode}`);
  const body = encodeURI(`The platform is not allowing me to register my bag with code ${registrationCode}  ${pin ? `and ${pin}` : ''}, could you please email me back ${email ? `at ${email}` : ''} with instructions on how to proceed?`);
  const csHref = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
  return (
    <a href={csHref} className={isButton ? supportContainerButton : supportContainer}>
      {text}
    </a>
  );
};

export default SupportContact;
