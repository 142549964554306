import 'firebase/auth';
import firebase from 'firebase/app';
import { auth } from './firebase';
import crud from '../api/crud';
import { isTrashieApp } from '../config/config';
import { EVENT_STREAM_APP_TYPES } from '../components/trashie/config/service';

export const signIn = async (email, password) => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  await auth.signInWithEmailAndPassword(email, password);
};

export const signInWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.setCustomParameters({
    prompt: 'select_account',
  });
  await auth.signInWithPopup(googleProvider);
};

export const signInWithFacebook = async () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  await auth.signInWithPopup(facebookProvider);
};

export const signOut = async () => {
  await auth.signOut();
};

export const createNewUser = async (email, password) => {
  const user = await auth.createUserWithEmailAndPassword(email, password);

  try {
    await crud.post({
      path: '/users',
      body: {
        data: {
          email,
          uid: user.user.uid,
          meta: {
            migrated: false,
            application: isTrashieApp() ? EVENT_STREAM_APP_TYPES.TRASHIE : '',
          },
        },
      },
    });
  } catch (e) {
    // do nothing
  }
  return user;
};

export const sendPasswordResetEmail = async (email) => {
  await auth.sendPasswordResetEmail(email);
};

export const getFirebaseToken = async () => {
  if (firebase.auth().currentUser) {
    await firebase.auth().currentUser.getIdToken();
  }
};

export const currentUser = async () => {
  await firebase.auth().currentUser.currentUser();
};

export const fetchUserInfo = async (userId) => {
  try {
    const userRef = firebase.firestore().collection('users').doc(userId);

    return userRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return {};
    }).catch((error) => error);
  } catch (error) {
    return error;
  }
};
