import * as React from 'react';
import {
  Image,
  Button,
} from 'react-bootstrap';
import tCoinLogoBlack from '../../../images/t-coin-logo-black.svg';
import copyIcon from '../../../images/copy-icon.svg';
import RightArrow from '../../../images/chevron-down.svg';

import Style from './TbbRewardPage.module.scss';

const {
  title,
  descriptions,
  copyCodeButton,
  balance,
  cardContainer,
  cardIconContainer,
  cardIcon,
  cardUserName,
  cardDescription,
  cardBalanceContainer,
  cardTopSection,
  cardBottomSection,
  balanceContainer,
  couponContainer,
  couponTittle,
  cardBottomSectionTrashie,
} = Style;

const TbbCardBalanceSection = ({
  creditAmt,
  storeConfig,
  couponData,
  handleCopy,
  balanceUrl,
}) => (

  <>
    <div className={balanceContainer}>
      <p className={title}>
        {storeConfig?.registerTbbFlow?.rewardStep?.title.replace(
          '[creditAmount]',
          storeConfig.isTrashie ?
            creditAmt :
            storeConfig?.registerTbbFlow?.rewardStep?.cashValue,
        )}
      </p>
      <p className={descriptions}>
        {storeConfig?.registerTbbFlow?.rewardStep?.description}
      </p>
    </div>
    <div className={couponContainer}>
      {!storeConfig.isTrashie && (
        <p className={couponTittle}>
          Your
          {' '}
          Exclusive
          {' '}
          {storeConfig?.title}
          {' '}
          Reward
        </p>
      )}
      <div
        style={{
          color: `${!storeConfig?.isTrashie && storeConfig.registerTbbFlow.rewardStep?.cardTextColor}`,
          background: `${!storeConfig?.isTrashie && storeConfig.registerTbbFlow.rewardStep?.cardBgColor}`,
          border: 'none',
        }}
        className={cardContainer}
      >
        <div className={cardBalanceContainer}>
          <div className={cardTopSection}>
            <div
              className={cardIconContainer}
              style={storeConfig.registerTbbFlow.rewardStep?.couponStyle}
            >
              <Image
                className={cardIcon}
                src={storeConfig.isTrashie ? tCoinLogoBlack :
                  storeConfig.registerTbbFlow.rewardStep?.logoCouponURL}
                alt="small-store-logo"
              />
            </div>
            {creditAmt ? (
              <div
                style={{
                  color: `${!storeConfig?.isTrashie && storeConfig.registerTbbFlow.rewardStep?.cardTextColor}`,
                }}
                className={balance}
              >
                {`$${creditAmt}`}
              </div>
            ) : null}
          </div>
          {storeConfig.isTrashie ?
            (
              <div
                className={cardBottomSectionTrashie}
                onClick={() => window.open(balanceUrl, '_blank')}
                aria-hidden="true"
              >
                <div>
                  <p className={cardDescription}>TRASHIECASH™ REWARD</p>
                  <p className={cardUserName}>
                    View my Balance
                  </p>
                </div>
                <div>
                  <img src={RightArrow} alt="arrow" />
                </div>
              </div>
            ) :
            (
              <div className={cardBottomSection}>
                <p className={cardDescription}>REWARD CODE</p>
                <p className={cardUserName}>{couponData?.couponCode}</p>
              </div>
            )}
        </div>
      </div>
    </div>
    {!storeConfig.isTrashie && (
      <Button className={copyCodeButton} onClick={handleCopy}>
        COPY CODE
        <Image
          style={{
            paddingLeft: '12px',
          }}
          src={copyIcon}
        />
      </Button>
    )}
  </>
);

export default TbbCardBalanceSection;
