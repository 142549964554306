import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  BROWSER_EXTENSION_URL,
  FAQS_URL,
  HOW_WE_RECYCLE_URL,
  TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL,
  TAKE_BACK_BAG_DOUBLE_RAINBOW_URL,
  TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
  TAKE_BACK_BAG_URL,
} from '../../config/externalURL';
import {
  ROUTE_REWARDS,
  ROUTE_LOGOUT,
  ROUTE_MY_ACCOUNT,
  ROUTE_MY_REWARDS,
  ROUTE_TAKE_BACK_BAGS,
  ROUTE_TRANSACTIONS,
  ROUTE_OFFERS,
} from '../../config/routes';
import closeIcon from '../../images/close-icon.svg';
import cardOfferIcon from '../../images/card-offer-icon.webp';
import cashHistoryIcon from '../../images/cash-history-icon.webp';
import threeDots from '../../images/three-dots.svg';
import iconCreditCard from '../../images/credit-card-icon.svg';
import iconFire from '../../images/icon-fire.svg';
import iconTarget from '../../images/icon-target.svg';
import iconLogout from '../../images/icon-logout.svg';
import iconPersonRisingHand from '../../images/icon-person-rising-hand.svg';
import iconSmilingFaceSunglasses from '../../images/icon-smiling-face-sunglasses.svg';
import iconSmilingFaceSunglassesYellow from '../../images/icon-smiling-face-sunglasses-yellow.svg';
import qrCodeIcon from '../../images/qr-code-icon.webp';
import extensionIcon from '../../images/extension-icon.svg';
import tbbFuchsiaMenu from '../../images/tbb-fuchsia-menu.png';
import tbbGreenMenu from '../../images/tbb-green-menu.png';
import tbbRainbowMenu from '../../images/tbb-rainbow-menu.png';
import earn from '../../images/Earn.svg';
import tbbDoubleRainbowMenu from '../../images/tbb-double-rainbow-menu.webp';
import { formatCurrencyNumber } from '../../utils/numbers';
import { getUserName } from '../../utils/utils';
import TCCoinSvg from '../SvgComponents/TCCoin_svg';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';

import styles from './SideNav.module.scss';
import CollapsableSection from '../CollapsableSection/CollapsableSection';

const {
  balance,
  line,
  icon,
  main,
  active,
  container,
  close,
  userContainer,
  userName,
  userEmail,
  menu,
  itemContainer,
  item,
  subMenu,
  subItemContainer,
  subItem,
  menuHeader,
  hamburguer,
  threeDotsIcon,
  myStuff,
  myStuffTitle,
  myStuffContent,
  myStuffItem,
  myStuffIcon,
} = styles;

const CONTROL_BUTTON_ID = 'sidenav-control';
const SIDENAV_ID = 'SideNav';

const menuItems = [
  {
    label: 'SHOP TAKE BACK BAG',
    imgSvg: iconFire,
    subItems: [
      {
        route: TAKE_BACK_BAG_URL,
        label: 'TAKE BACK BAG',
        img: tbbFuchsiaMenu,
        external: true,
      },
      {
        route: TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
        label: 'RAINBOW 3-PACK',
        img: tbbRainbowMenu,
        external: true,
      },
      {
        route: TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL,
        label: 'COTTON FABRIC SCRAP',
        img: tbbGreenMenu,
        external: true,
      },
      {
        route: TAKE_BACK_BAG_DOUBLE_RAINBOW_URL,
        label: 'DOUBLE RAINBOW DEAL',
        img: tbbDoubleRainbowMenu,
        external: true,
      },
    ],
  },
  {
    route: ROUTE_REWARDS,
    label: 'TRASHIECASH™ REWARDS',
    imgSvg: iconCreditCard,
    external: false,
  },
  {
    route: ROUTE_OFFERS,
    label: 'EARN TrashieCash™',
    imgSvg: earn,
    external: false,
  },
  {
    route: BROWSER_EXTENSION_URL,
    label: 'CHROME EXTENSION',
    imgSvg: extensionIcon,
    external: true,
  },
  {
    route: HOW_WE_RECYCLE_URL,
    label: 'HOW WE RECYCLE',
    imgSvg: iconTarget,
    external: true,
  },
  {
    route: ROUTE_MY_ACCOUNT,
    label: 'MY ACCOUNT',
    imgSvg: iconSmilingFaceSunglasses,
  },
  {
    route: FAQS_URL,
    label: 'TRASHIE FAQS',
    imgSvg: iconPersonRisingHand,
    external: true,
  },
  {
    route: ROUTE_LOGOUT,
    label: 'LOGOUT',
    imgSvg: iconLogout,
  },
];

const myStuffItems = [
  {
    route: ROUTE_MY_REWARDS,
    label: 'My Wallet',
    imgSvg: cardOfferIcon,
  },
  {
    route: ROUTE_TAKE_BACK_BAGS,
    label: 'My Labels',
    imgSvg: qrCodeIcon,
  },
  {
    route: ROUTE_TRANSACTIONS,
    label: 'Transactions',
    imgSvg: cashHistoryIcon,
  },
];

const SideNav = ({
  iconClassName = '',
}) => {
  const { isSignedIn, loading, userData } = useFirebaseAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [subMenuCollapsed, setSubMenuCollapsed] = useState(true);

  const userDisplayName = useMemo(() => (
    getUserName(userData, false)
  ), [userData]);

  const toggleSidebar = useCallback(() => {
    setIsOpen(prev => {
      if (prev) {
        setSubMenuCollapsed(true);
      }

      return !prev;
    });
  }, []);

  const closeSidebar = useCallback((event) => {
    if (
      event?.target?.closest(`#${SIDENAV_ID}`) ||
      event?.target?.parentElement?.id === CONTROL_BUTTON_ID ||
      event?.target?.id === CONTROL_BUTTON_ID
    ) {
      return;
    }

    setIsOpen(false);
    setSubMenuCollapsed(true);
  }, [toggleSidebar]);

  useEffect(() => {
    document.addEventListener('click', closeSidebar);

    return () => {
      document.removeEventListener('click', closeSidebar);
    };
  }, []);

  if (!isSignedIn && !loading) {
    return null;
  }

  return (
    <>
      {userData && (
        <>
          <div className={balance}>
            <p>
              {formatCurrencyNumber(userData.balance?.currentBalance, new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
              }))}
            </p>
            <TCCoinSvg width="14" height="14" color="#1B1A1B" />
          </div>
          <span className={line} />
        </>
      )}
      <button
        id={CONTROL_BUTTON_ID}
        type="button"
        className={`${icon} ${iconClassName}`}
        onClick={toggleSidebar}
      >
        <img className={hamburguer} src={iconSmilingFaceSunglassesYellow} alt="smiling face icon" />
        <img className={threeDotsIcon} src={threeDots} alt="three dots icon" />
      </button>
      <nav id={SIDENAV_ID} className={`${main} ${isOpen ? active : ''}`}>
        <div className={container}>
          <div className={menuHeader}>
            <img src={iconSmilingFaceSunglassesYellow} alt="smiling face icon" />
            <div className={userContainer}>
              {userDisplayName && (
                <span
                  className={userName}
                  title={userDisplayName}
                >
                  {userDisplayName}
                </span>
              )}
              <span
                className={userEmail}
                title={userData.email}
              >
                {userData.email}
              </span>
            </div>
            <button type="button" className={close} onClick={toggleSidebar}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          <div className={myStuff}>
            <div className={myStuffTitle}>MY STUFF</div>
            <div className={myStuffContent}>
              {myStuffItems.map(({ route, label, imgSvg }) => (
                <Link
                  key={`myStuffItem-${label}`}
                  className={myStuffItem}
                  to={route}
                  onClick={toggleSidebar}
                >
                  <div className={myStuffIcon}>
                    <img src={imgSvg} alt="icon" />
                  </div>
                  {label}
                </Link>
              ))}
            </div>
          </div>
          {menuItems.length && (
            <ul className={menu}>
              {menuItems.map(({ subItems, route, label, imgSvg, external }) => {
                const listItem = (
                  <>
                    {imgSvg && <img src={imgSvg} alt="icon" />}
                    {label}
                  </>
                );

                return (
                  <li className={itemContainer} key={`menuItem-${label}`}>
                    {subItems ? (
                      <CollapsableSection
                        title={<div className={item}>{listItem}</div>}
                        content={(
                          <ul className={subMenu} key={`subMenu-${label}`}>
                            {subItems.map(({
                              route: subItemRoute,
                              label: subItemLabel,
                              img: subItemImg,
                              external: subItemExternal,
                            }) => (
                              <li
                                className={subItemContainer}
                                key={`subMenuItem-${subItemLabel}`}
                                style={{ backgroundImage: `url(${subItemImg})` }}
                              >
                                {(subItemExternal && (
                                  <a className={subItem} href={subItemRoute}>{subItemLabel}</a>
                                )) || (
                                  <Link
                                    className={subItem}
                                    to={subItemRoute}
                                    onClick={toggleSidebar}
                                  >
                                    {subItemLabel}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                        collapsed={subMenuCollapsed}
                        onChange={collapsed => setSubMenuCollapsed(collapsed)}
                      />
                    ) : (
                      (external && (
                        <a className={item} href={route}>{listItem}</a>
                      )) || (
                        <Link className={item} to={route} onClick={toggleSidebar}>{listItem}</Link>
                      )
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </nav>
    </>
  );
};

export default SideNav;
