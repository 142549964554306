import { useEffect } from 'react';
import { isTrashieApp } from '../config/config';

const useScript = (innerHTML) => {
  if (!innerHTML) {
    return;
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.innerHTML = innerHTML;

    document.head.appendChild(script);

    return () => {
      if (isTrashieApp()) {
        document.head.removeChild(script);
      }
    };
  }, [innerHTML]);
};

export default useScript;
