import { useState } from 'react';
import { Button } from 'react-bootstrap';
import InfoSection from '../InfoSection/InfoSection';
import PinComponent from '../../../views/Redemption/Components/PinComponent';
import SvgComponentArrow from '../SvgComponentArrow';
import style from './TbbPin.module.scss';
import crud from '../../../../api/crud';

const {
  infoContainer,
  container,
  buttonContainer,
  button,
  pinContainer,
  pinLegend,
  invalidText,
  pinBox,
} = style;
const Tbbpin = ({
  storeConfig,
  setPinValues,
  pinValues,
  handleOnclick,
  registerCode }) => {
  const [isValidPin, setIsValidPin] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [attempts, setAttempts] = useState(5);

  const handlePinCode = async () => {
    setWaiting(true);
    const response = await crud.post({
      path: '/redemptions/validatePinCode',
      body: {
        data: {
          id: registerCode,
          pin: pinValues.join(''),
        },
      },
    });

    if (response.invalid) {
      setIsValidPin(false);
      setAttempts(response.attempts);
    } else {
      handleOnclick();
    }
    setWaiting(false);
  };

  const onHandlePinChange = (pin) => {
    setIsValidPin(true);
    setPinValues(pin);
  };

  return (
    <div className={container}>
      <div>
        <div className={infoContainer}>
          <InfoSection
            titleText="Enter your Bag Pin Number"
            descriptionText={storeConfig?.registerTbbFlow.enterPinStep.descriptionText}
          />
        </div>
        <div className={pinContainer}>
          <PinComponent
            isValidPin={isValidPin}
            pinValues={pinValues}
            setPinValues={onHandlePinChange}
            styleProps={{ pinLegend, pinBox, background: !storeConfig.isTrashie ? '#F2F2F2' : '' }}
          />
          <p className={invalidText}>
            {!isValidPin && `Invalid Pin. You have ${attempts} ${storeConfig?.registerTbbFlow.enterPinStep.wrongPinText}`}
          </p>
        </div>

      </div>

      <div className={buttonContainer}>
        <Button
          className={button}
          style={{
            background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            color: storeConfig.registerTbbFlow?.nextButton?.textColor,
            border: !storeConfig.isTrashie && storeConfig.registerTbbFlow?.nextButton?.bgColor,
          }}
          onClick={() => handlePinCode()}
          disabled={pinValues.join('').length < 6 || waiting || attempts === 0}
        >
          CONTINUE
          <SvgComponentArrow fill={pinValues.join('').length < 6 || waiting || attempts === 0 ? '#6F706F' : storeConfig.registerTbbFlow?.nextButton?.textColor} />
        </Button>
      </div>
    </div>
  );
};

export default Tbbpin;
