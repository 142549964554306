import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import SupportContact from '../SupportContact';
import TrashieBanner from '../../images/the-take-back-bag-plus-starts.svg';
import SvgComponentArrow from '../SvgComponentArrow';
import style from './RegisterTbb.module.scss';

const {
  container,
  textContainer,
  centerText,
  buttonContainer,
  supportText,
  button,
  imageContainer,
  trashieBanner,
  failCodeTextContainer,
} = style;
const RegisterTbb = ({ isValidCode, handleOnclick, storeConfig, registerCode }) => {
  const isTrashieBanner = storeConfig.isTrashie || !storeConfig.mainLogoSrc;

  return (
    <div className={container}>
      <div className={classNames(imageContainer, { [trashieBanner]: isTrashieBanner })}>
        <img
          src={isTrashieBanner ? TrashieBanner : storeConfig.mainLogoSrc}
          alt="TrashieBanner"
          style={storeConfig?.registerTbbFlow?.landingPageStep?.logoStyleProperties}
        />
      </div>
      <div className={isValidCode ? textContainer : failCodeTextContainer}>
        {isValidCode ? (
          <div className={centerText}>
            {storeConfig?.registerTbbFlow?.landingPageStep?.description ?
              storeConfig?.registerTbbFlow?.landingPageStep?.description : (
                <>
                  REGISTER YOUR TAKE BACK BAG
                  <br />
                  AND GET YOUR TRASHIECASH™
                </>
              ) }

          </div>
        ) : (
          <div className={centerText}>
            The bag code you are trying
            <br />
            to register was not found
          </div>
        ) }
        {!isValidCode && <div className={supportText}>Please verify it or contact support.</div>}
      </div>
      <div className={buttonContainer}>
        <Button
          className={button}
          style={{
            background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            color: storeConfig.registerTbbFlow?.nextButton?.textColor,
            border: !storeConfig.isTrashie && storeConfig?.registerTbbFlow?.nextButton?.bgColor,
          }}
          type="button"
          onClick={() => isValidCode && handleOnclick()}
        >
          {isValidCode ?
            storeConfig.registerTbbFlow?.landingPageStep?.button.text ?? 'LET’S GET STARTED ' :
            <SupportContact registrationCode={registerCode} isButton />}
          {isValidCode &&
          <SvgComponentArrow fill={storeConfig.registerTbbFlow?.nextButton?.textColor} /> }

        </Button>
      </div>
    </div>
  );
};

export default RegisterTbb;
