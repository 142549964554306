import './DisclaimerPublic.scss';

const DisclaimerPublic = ({
  termsUrl,
  policyUrl,
  prefix = '',
  suffix = '',
}) => (
  <div className="DisclaimerPublic">
    {prefix && prefix}
    By continuing you agree to our
    <a className="DisclaimerPublic__link" href={termsUrl} target="_blank" rel="noreferrer">
      Terms of service
    </a>
    and
    <a className="DisclaimerPublic__link" href={policyUrl} target="_blank" rel="noreferrer">
      Privacy policy.
    </a>
    {suffix && ` ${suffix} `}
  </div>
);

export default DisclaimerPublic;
