import { Modal, Button } from 'react-bootstrap';
import './QrModal.scss';
import Printer from '../../images/printer-icon.svg';
import closeIcon from '../../images/close-icon.svg';
import helpCircle from '../../images/help-circle.svg';

const QrModal = ({ show, setShow, qrUrl, pdfUrl, expireDate }) => (
  <Modal
    show={show}
    onHide={() => setShow(false)}
    centered
    dialogClassName="QrModal"
  >
    <Modal.Header className="modalHeader">
      <Button variant="link" onClick={() => setShow(false)} className="closeModalButtonHeader">
        <img className="iconClose" src={closeIcon} alt="close-icon" />
      </Button>
    </Modal.Header>
    <Modal.Body>
      <div className="container">
        <div className="modalAdvice">
          <div className="titleModal">
            shipping label
          </div>
          <div className="textModal">
            Show the mobile code at any USPS location to send back your Take Back Bag.
          </div>
          <div>
            <div className="expiresDate">
              <div className="title">Expires by:</div>
              <div className="date">{expireDate}</div>
            </div>
          </div>
        </div>

        <img src={qrUrl} alt="Qr" className="qrImage" />
        <a className="printLabel" href={pdfUrl} target="_blank" rel="noreferrer">
          print label
          {' '}
          <img src={Printer} alt="printer" />
        </a>
        <div className="disclaimerContainer">
          <img src={helpCircle} alt="help-circle" />
          <div>
            Regenerate an expired label through My Account or
            by rescanning the QR code on the corner of your Take Back Bag.
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default QrModal;
