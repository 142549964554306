/* eslint-disable no-debugger */
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import LogRocket from 'logrocket';

import GlobalLogin from '../../global/GlobalLogin';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';

import { EMAIL_SEARCH_PARAM, PROMO_ID_PARAM, TYPE_SEARCH_PARAM } from '../../config/routes';
import { EVENT_STREAM_APP_TYPES, EVENT_STREAM_EVENT_TYPES } from '../../config/service';
import { sendEventStream } from '../../utils/service';
import { getUserName } from '../../utils/utils';
import { getSearchParam } from '../../utils/routes';
import { getGenericError } from '../../utils/errors';
import {
  getRedirectPath,
  getURIParam,
  getViewType,
  getWildfireDeviceId,
  WF_DEVICE_ID_SEARCH_PARAM,
} from './utils';

import './Login.scss';

// TODO - Implement URI whitelist from env var
/* const uriWhitelist = [
  'chrome-extension://extensionidhere/auth.html',
  'https://closet.fordays.com', // Only for testing purposes
]; */

const Login = () => {
  const { search } = useLocation();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();
  const setAlert = useAlert();
  const posthog = usePostHog();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState('');

  const query = new URLSearchParams(search);
  const uriParam = getURIParam(query);
  const wfedidParam = getWildfireDeviceId(query);
  const typeParam = getSearchParam(query, TYPE_SEARCH_PARAM);
  const emailParam = getSearchParam(query, EMAIL_SEARCH_PARAM);
  const promoId = getSearchParam(query, PROMO_ID_PARAM);

  const redirect = useCallback(async () => {
    if (!isSignedIn || !userData || isLoadingFirebaseAuth || isLoading) {
      return;
    }

    setIsLoading(true);

    const {
      affiliate_cid: affiliateCID,
      email,
      error,
    } = userData;

    if (error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });

      setIsLoading(false);
      return;
    }

    const uuid = affiliateCID.replaceAll('-', '');

    // Posthog start
    posthog.identify(
      uuid,
      {
        email,
        [WF_DEVICE_ID_SEARCH_PARAM]: wfedidParam,
      },
    );

    if (wfedidParam) {
      posthog.alias(wfedidParam, uuid);
    }
    // Posthog end

    // Logrocket start
    LogRocket.identify(String(uuid), {
      email,
      name: getUserName(userData),
    });
    // Logrocket end

    if (promoId && userData.visitCount < 1) {
      await sendEventStream({
        event: EVENT_STREAM_EVENT_TYPES.USER_REGISTERED,
        application: EVENT_STREAM_APP_TYPES.TRASHIE,
        data: { promoId },
      });
    }

    await sendEventStream({
      event: uriParam && wfedidParam ?
        EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN_WF_EXTENSION :
        EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN,
      application: EVENT_STREAM_APP_TYPES.TRASHIE,
    });

    window.location.href = getRedirectPath(query, uuid, history, view);
  }, [isSignedIn, userData, search, isLoadingFirebaseAuth, isLoading]);

  const handleOnViewChange = useCallback((currentView) => {
    setView(currentView);
  }, []);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth) {
      redirect();
    }
  }, [isSignedIn, userData, isLoadingFirebaseAuth, isLoading]);

  return (
    <div className="Login">
      <GlobalLogin
        external={Boolean(uriParam)}
        initialView={getViewType(typeParam)}
        initialEmail={emailParam}
        onViewChange={handleOnViewChange}
        isParentLoading={isLoading || isLoadingFirebaseAuth}
      />
    </div>
  );
};

export default Login;
