import style from './StepProgressBar.module.scss';

const { progressBar, progress } = style;

const StepProgressBar = ({ currentStep = 0, totalSteps = 1 }) => {
  const progressStep = (currentStep / totalSteps) * 100;

  return (
    <div className={progressBar}>
      <div className={progress} style={{ width: `${progressStep}%` }} />
    </div>
  );
};

export default StepProgressBar;
