import { Link } from 'react-router-dom';
import style from './RegisterTbbContainer.module.scss';
import LockIcon from '../../images/lock-icon.svg';
import helpCircle from '../../images/help-circle.svg';
import sendMail from '../../images/mail-send.svg';
import StepProgressBar from '../../global/StepProgessBar';
import TcoinLogo from '../../images/t-coin-logo-black.svg';
import SupportContact from '../../global/SupportContact';

const {
  header,
  headerImage,
  subHeader,
  footer,
  footerContent,
  container,
  content,
  progressBar,
  codeHeader,
  stepZeroFooter,
  logoContainer,
  codeContainer,
} = style;
const RegisterTbbContainer = ({ pin, email, qrFailed, tab, registerCode = '000000', currentStep = 1, storeConfig, children }) => {
  const stepConfig = {
    0: {
      footerInstructions: 'Powered by Trashie',
      footerIcon: TcoinLogo,
    },
    1: {
      footerInstructions: 'PIN Code not working? ',
      footerIcon: helpCircle,
      component: <SupportContact text="Contact us for help." registrationCode={registerCode} pin={pin} email={email} />,
    },
    2: {
      footerInstructions: 'We need your email address to make sure that you receive your reward.',
      footerIcon: LockIcon,
    },
    3: {
      footerInstructions: 'We use your address to create your shipping label.',
      footerIcon: sendMail,
    },
    4: {
      footerInstructions: 'We use your address to create your shipping label. We never share your personal information with anyone.',
      footerIcon: sendMail,
    },
    5: {
      footerInstructions: 'Regenerate an expired label through My Account or by rescanning the QR code on the corner of your Take Back Bag.',
      footerIcon: helpCircle,
    },
    6: {},
  };

  const progressBarcontainerBgColor = currentStep === 6 ? 'white' : '';

  return (
    <div className={container}>
      {currentStep !== 0 && (
        <div className={header}>
          <div className={subHeader} style={currentStep === 6 ? { borderBottom: '1px solid #292829', background: `${storeConfig.isTrashie ? '#FAB4F3' : null}` } : null}>
            <div className={headerImage} style={currentStep === 6 ? { maxWidth: '1316px', padding: '0' } : null}>
              <img
                src={storeConfig.mainLogoSrc}
                alt="recycle bag"
                style={storeConfig?.registerTbbFlow?.headerLogoProperties}
              />
            </div>
          </div>
          {currentStep !== 6 && (
          <div className={codeHeader}>
            <div className={codeContainer}>
              <div>
                {`ID: ${registerCode}`}
              </div>
              {!storeConfig.isTrashie && (
              <div className={logoContainer}>
                Powered by Trashie
                <img src={TcoinLogo} alt="logo" />
              </div>
              )}
            </div>
          </div>
          )}

        </div>
      )}

      <div className={content}>
        {((currentStep !== 0 && currentStep !== 6) && (
          <div style={{ backgroundColor: progressBarcontainerBgColor }}>
            <div className={progressBar} style={{ backgroundColor: progressBarcontainerBgColor }}>
              <StepProgressBar currentStep={currentStep} totalSteps={6} />
            </div>
          </div>
        )
        )}
        <div>
          {children}
        </div>
      </div>
      {tab !== 'RedemptionCouponView' && (
        <div className={currentStep !== 0 ? footer : stepZeroFooter}>
          <div className={footerContent}>
            <img src={stepConfig[currentStep]?.footerIcon} alt=" " />
            {qrFailed && storeConfig.isTrashie ?
              (
                <div>
                  Still not working?
                  {' '}
                  <Link
                    to={{ pathname: 'https://www.trashie.io/contact' }}
                    target="_blank"
                  >
                    <SupportContact registrationCode={registerCode} pin={pin} email={email} />
                  </Link>
                </div>
              ) :
              (
                <div>
                  {stepConfig[currentStep]?.footerInstructions}
                  {stepConfig[currentStep]?.component}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterTbbContainer;
