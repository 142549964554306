import { useEffect, useState } from 'react';
import ContentWrapper from '../../../global/ContentWrapper';
import LoadingScreen from '../../../redeem/qrCode/components/LoadingScreen';

const LoadingComponent = ({ initialDate }) => {
  const [loadingMessage, setLoadingMessage] = useState('loading');

  const validateMessage = (currentDate) => {
    const diffTime = Math.trunc((currentDate.getTime() - initialDate.getTime()) / 1000);
    if (diffTime > 4 && diffTime <= 8) {
      setLoadingMessage('This is taking a little longer, please wait.');
    }

    if (diffTime > 8 && diffTime <= 14) {
      setLoadingMessage('We are still working on this, please wait.');
    }

    if (diffTime > 14) {
      setLoadingMessage('We are almost done processing your QR code, please wait.');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      validateMessage(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ContentWrapper>
      <LoadingScreen message={loadingMessage} />
    </ContentWrapper>
  );
};

export default LoadingComponent;
