// /* eslint-disable prefer-template */
import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import style from './FromAddressModal.module.scss';
import InfoSection from '../../trashie/global/InfoSection/InfoSection';
import SvgComponentArrow from '../../trashie/global/SvgComponentArrow';
import { isNullOrEmpty } from '../../trashie/utils/utils';

const {
  buttonContainer,
  textInput,
  modalLabelText,
  button,
  modalBodyContainer,
  smallTextRed,
  inputTextUpperCasse,
  optionLabel,
  formContainer,
} = style;

const FromAddressModal = ({
  storeConfig,
  address,
  setAddress,
  handleAddressStep,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [apt, setApt] = useState('');
  const [city, setCity] = useState('');
  const [provinceCode, setProvinceCode] = useState('');
  const [zip, setZip] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [provinceCodeError, setProvinceCodeError] = useState(false);
  const [zipError1, setZipError1] = useState(false);
  const [zipError2, setZipError2] = useState(false);
  const states = [
    'Alabama-AL',
    'Alaska-AK',
    'Arizona-AZ',
    'Arkansas-AR',
    'California-CA',
    'Colorado-CO',
    'Connecticut-CT',
    'Delaware-DE',
    'District of Columbia-DC',
    'Florida-FL',
    'Georgia-GA',
    'Hawaii-HI',
    'Idaho-ID',
    'Illinois-IL',
    'Indiana-IN',
    'Iowa-IA',
    'Kansas-KS',
    'Kentucky-KY',
    'Louisiana-LA',
    'Maine-ME',
    'Maryland-MD',
    'Massachusetts-MA',
    'Michigan-MI',
    'Minnesota-MN',
    'Mississippi-MS',
    'Missouri-MO',
    'Montana-MT',
    'Nebraska-NE',
    'Nevada-NV',
    'New Hampshire-NH',
    'New Jersey-NJ',
    'New Mexico-NM',
    'New York-NY',
    'North Carolina-NC',
    'North Dakota-ND',
    'Ohio-OH',
    'Oklahoma-OK',
    'Oregon-OR',
    'Pennsylvania-PA',
    'Puerto Rico-PR',
    'Rhode Island-RI',
    'South Carolina-SC',
    'South Dakota-SD',
    'Tennessee-TN',
    'Texas-TX',
    'Utah-UT',
    'Vermont-VT',
    'Virginia-VA',
    'Washington-WA',
    'West Virginia-WV',
    'Wisconsin-WI',
    'Wyoming-WY',
  ];

  useEffect(() => {
    if (address.firstName) {
      setFirstName(address.firstName);
    }
    if (address.lastName) {
      setLastName(address.lastName);
    }
    if (address.address1) {
      setAddress1(address.address1);
    }
    if (address.address2) {
      setApt(address.address2);
    }
    if (address.city) {
      setCity(address.city);
    }
    if (address.provinceCode) {
      setProvinceCode(address.provinceCode);
    }
    if (address.zip) {
      setZip(address.zip);
    }
  }, []);

  const handleSubmit = async (e) => {
    if (isNullOrEmpty(firstName)) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (isNullOrEmpty(lastName)) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (isNullOrEmpty(address1)) {
      setAddress1Error(true);
    } else {
      setAddress1Error(false);
    }
    if (isNullOrEmpty(city)) {
      setCityError(true);
    } else {
      setCityError(false);
    }
    if (isNullOrEmpty(provinceCode)) {
      setProvinceCodeError(true);
    } else {
      setProvinceCodeError(false);
    }
    if (!zip.length) {
      setZipError2(false);
      setZipError1(true);
    } else if (zip.length > 5 || zip.length < 5 || !Number(zip)) {
      setZipError1(false);
      setZipError2(true);
    } else {
      setZipError1(false);
      setZipError2(false);
    }
    if (
      firstName.length &&
      lastName.length &&
      !isNullOrEmpty(address1) &&
      !isNullOrEmpty(city) &&
      zip.length === 5 &&
      Number(zip) &&
      provinceCode.length
    ) {
      const firstNameCaps =
        firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const lastNameCaps = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      const cityCaps = city.charAt(0).toUpperCase() + city.slice(1);
      const address1Caps = address1.charAt(0).toUpperCase() + address1.slice(1);
      const addressObject = {
        firstName: firstNameCaps,
        lastName: lastNameCaps,
        address1: address1Caps,
        address2: apt,
        city: cityCaps,
        provinceCode,
        zip: zip.replace(/\s/g, ''),
      };
      await setAddress(addressObject);
      handleAddressStep(e, addressObject);
    }
  };

  let statesCounter = 0;

  return (
    <div className={modalBodyContainer}>
      <div>
        <InfoSection titleText={address.firstName ? 'Update your mailing information' : 'Enter your mailing information'} descriptionText="" />
        <div className={formContainer}>
          <div className="d-flex flex-row justify-content-between">
            <div className="w-100">
              <div className="d-flex align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`} style={{ marginTop: '0px !important' }}>First name*</p>
                {firstNameError && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
              </div>
              <Form.Control
                value={firstName}
                className={`${inputTextUpperCasse} ${textInput}`}
                onChange={({ target }) => setFirstName(target.value)}
                style={{ color: storeConfig.isTrashie && '#FCFFFD !important' }}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="w-100">
              <div className="d-flex align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>Last name*</p>
                {lastNameError && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
              </div>
              <Form.Control
                value={lastName}
                className={`${inputTextUpperCasse} ${textInput}`}
                onChange={({ target }) => setLastName(target.value)}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="w-100">
              <div className="d-flex align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>Address*</p>
                {address1Error && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
              </div>
              <Form.Control
                value={address1}
                className={`${inputTextUpperCasse} ${textInput}`}
                onChange={({ target }) => setAddress1(target.value)}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="w-100">
              <div className="d-flex align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>Apt or Suite</p>
              </div>
              <Form.Control
                className={`${inputTextUpperCasse} ${textInput}`}
                value={apt}
                onChange={({ target }) => setApt(target.value)}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between w-100">
            <div className="w-100">
              <div className="d-flex flex-row align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>Zip Code*</p>
                {zipError1 && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
                {zipError2 && (
                  <small className={`${smallTextRed} text-red`}>invalid zip code</small>
                )}
              </div>
              <Form.Control
                className={`${inputTextUpperCasse} ${textInput}`}
                value={zip}
                onChange={({ target }) => setZip(target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between style-modal-mobile">
            <div className="w-100">
              <div className="d-flex flex-row align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>City*</p>
                {cityError && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
              </div>
              <Form.Control
                value={city}
                className={`${inputTextUpperCasse} ${textInput}`}
                onChange={({ target }) => setCity(target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between style-modal-mobile">
            <div className="w-100">
              <div className="d-flex flex-row align-items-end">
                <p className={`mb-0 redemption-form-labels ${modalLabelText}`}>
                  State*
                </p>
                <p className="mb-0 redemption-form-labels style-city-state-title-nd">
                  &nbsp;
                </p>
                {provinceCodeError && (
                  <small className={`${smallTextRed} text-red`}>required field</small>
                )}
              </div>
              <Form.Select
                className={provinceCode !== '' ? `${inputTextUpperCasse} ${textInput} active` : `${inputTextUpperCasse} ${textInput}`}
                value={provinceCode}
                defaultValue={provinceCode}
                onChange={({ target }) => {
                  setProvinceCode(target.value);
                }}
              >
                <option value="">State</option>
                {states.map((state) => (
                  <option className={optionLabel} key={statesCounter++} value={state.split('-')[1]}>
                    {state.split('-')[0]}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className={buttonContainer}>
          <Button
            style={{
              background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
              color: storeConfig.registerTbbFlow?.nextButton?.textColor,
              border: !storeConfig.isTrashie && storeConfig.registerTbbFlow?.nextButton?.bgColor,
            }}
            type="button"
            className={button}
            onClick={handleSubmit}
          >
            CONTINUE
            <SvgComponentArrow fill={storeConfig.registerTbbFlow?.nextButton?.textColor} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FromAddressModal;
