import './DotsLoader.scss';

const DotsLoader = () => (
  <div className="DotsLoader">
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </div>
);

export default DotsLoader;
